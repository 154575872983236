<template>
  <div v-if="show" class="d-flex flex-column flex-fill white pb-5">
    <div class="text-center">
      <h1 class="orange--text">Consignado</h1>
      <h3>Fisiopower</h3>
      <span> <strong>CNPJ: </strong>21.267.979/0001-39 </span><br />
      <span> <strong>Telefone: </strong>33287917 </span><br />
      <span><strong>Endereço: </strong>Rua Deputado Nilson Ribas, nº 987</span>
    </div>
    <hr />
    <div class="d-flex justify-space-around my-5">
      <span> <strong>Nº: </strong>{{ request.id }} </span>
      <span> <strong>Vendedor: </strong>{{ request.salesman.name }} </span>
      <span>
        <strong>Data: </strong
        >{{ request.date | moment("DD/MM/YYYY hh:mm:ss") }}</span
      >
    </div>
    <hr />
    <div class="client my-5 mx-10">
      <div class="colspan">
        <strong>Cliente: </strong>{{ request.client && request.client.name }}
      </div>
      <div>
        <strong>CNPJ/CPF: </strong
        >{{
          request.client.cnpj.length ? request.client.cnpj : request.client.cpf
        }}
      </div>
      <div><strong>Fone: </strong>{{ request.client.phone }}</div>
      <div class="colspan">
        <strong>Endereço: </strong>{{ request.client.street }}
      </div>
      <div><strong>Complemento: </strong>{{ request.client.complement }}</div>
      <div><strong>Cidade: </strong>{{ request.client.municipality }}</div>
    </div>
    <hr />
    <div class="my-5 mx-10">
      <table>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Qtd.</th>
            <th>Valor Unitário</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item of request.products" :key="item.id">
            <td>{{ item.product.name }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.unitary_value | money }}</td>
            <td>{{ (item.amount * item.unitary_value) | money }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3" class="text-right pr-2">
              <strong>Subtotal:</strong>
            </td>
            <td>{{ totalProducts | money }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <hr />
    <div class="my-5 mx-10">
      <h2 class="text-center grey--text">Produtos Devolvidos</h2>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Produto</th>
            <th>Qtd.</th>
            <th>Valor Unitário</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item of request.products">
            <template v-if="item.devolutionItem">
              <tr v-for="dp of item.devolutionItem" :key="dp.id">
                <td>{{ dp.date | moment("DD/MM/YYYY hh:mm:ss") }}</td>
                <td>{{ item.product.name }}</td>
                <td>{{ dp.amount }}</td>
                <td>{{ item.unitary_value | money }}</td>
                <td>{{ (dp.amount * dp.price) | money }}</td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <hr />
    <div class="d-flex justify-space-between mx-10 signature">
      <p>{{ request.salesman && request.salesman.name }}</p>
      <p>{{ request.client && request.client.name }}</p>
    </div>
  </div>
</template>

<script>
import mixinsSnack from "../../_mixins/snackbar";
export default {
  name: "Request",
  mixins: [mixinsSnack],
  data() {
    return {
      show: false,
      request: {},
    };
  },
  beforeMount() {
    const { id } = this.$route.params;
    if (id) {
      this.$axios(`/request/${id}`)
        .then(({ data }) => {
          this.request = data;
          this.show = true;
        })
        .catch(() => {
          this.snackError("Consignado não encontrado!");
          this.$router.push("/");
        });
    } else {
      this.snackError("Consignado não foi informado!");
      this.$router.push("/");
    }
  },
  computed: {
    totalProducts() {
      const totals = this.request.products.map(
        (i) => i.amount * i.unitary_value
      );
      return totals.reduce((a, c) => a + c, 0);
    },
    devolutionProducts() {
      let arr;
      this.request.products.map((p) => {
        if (p.devolutionItem) {
          p.devolutionItem.map((o) => arr.push(o));
        }
      });
      return arr;
    },
  },
};
</script>

<style scoped>
hr {
  border: 1px solid #f2f2f2;
}
.client {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.client .colspan {
  grid-column: span 2;
}

table {
  width: 100%;
  border-collapse: collapse;
}
thead {
  background-color: #333;
  color: #ccc;
}

thead th {
  padding: 3px 0;
}
tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
td {
  padding: 3px 0;
  text-align: center;
}
table.payments {
  width: 450px;
}
.signature {
  margin: 100px 0 0 0;
}
.signature p {
  text-align: center;
  border-top: 1px solid black;
  width: 50%;
}
.signature p + p {
  margin-left: 100px;
}
</style>
